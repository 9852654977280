@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url('./fonts/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamBold';
  src: local('GothamBold'), url('./fonts/GothamBold.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamLight';
  src: local('GothamLight'), url('./fonts/GothamLight.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'), url('./fonts/GothamBook.ttf') format('truetype');
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #EFFBFF;
}