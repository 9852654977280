.workshops-box {
  box-shadow: -12px -12px #0d9be77a;
}

.mySwiper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: #3a4856 2px solid;
  opacity: 1;
}

.mySwiper .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background-color: #313d5b;
  border: #3a4856 2px solid;
}

.faqSwiper .swiper-button-prev {
  width: 46px;
  height: 46px;
  border-radius: 1000px;
  border: #ead300 2px solid;
  background-color: transparent;
  color: #ead300;
}
.faqSwiper {
  display: flex;
  align-items: center;
}

.faqSwiper .swiper-button-prev::after {
  font-size: 20px;
  font-weight: 700;
  margin-right: 3px;
}

.faqSwiper .swiper-button-next {
  width: 46px;
  height: 46px;
  border-radius: 1000px;
  border: #ead300 2px solid;
  background-color: #ead300;
  color: white;
}

.faqSwiper .swiper-button-next::after {
  font-size: 20px;
  font-weight: 700;
  margin-left: 3px;
}

.faqSwiper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: white 2px solid;
  opacity: 1;
}

.faqSwiper .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background-color: white;
  border: white 2px solid;
}

.wilayaSwiper .swiper-button-prev {
  width: 46px;
  height: 46px;
  margin-right: 50px;
  border-radius: 1000px;
  border: #0d99e7 2px dashed;
  background-color: transparent;
  color: #0d99e7;
}

.wilayaSwiper .swiper-button-prev::after {
  font-size: 20px;
  font-weight: 700;
  margin-right: 3px;
}

.wilayaSwiper .swiper-button-next {
  width: 46px;
  height: 46px;
  margin-left: 50px;
  border-radius: 1000px;
  border: #0d99e7 2px solid;
  background-color: #0d99e7;
  color: white;
}

.wilayaSwiper .swiper-button-next::after {
  font-size: 20px;
  font-weight: 700;
  margin-left: 3px;
}

.wilayaSwiper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: #3a4856 2px solid;
  opacity: 1;
}

.wilayaSwiper .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background-color: #313d5b;
  border: #3a4856 2px solid;
}
